@import 'theme/fonts';
@import 'theme/colors';

.form {
	&-group {
		position: relative;
	}
	&-error {
		position: absolute;
		left: 6px;
		z-index: 2;
		bottom: -16px;
		font-size: 12px;
		width: 100%;
		font-family: 'Cera Pro Medium', sans-serif;
		font-weight: 600;
		color: var(--red);
	}
}


@import "@angular/cdk/overlay-prebuilt.css";
.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}
.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

body  #modal-gallery-container{
  min-height: 50vh !important;
  @media (max-width: 1279px) {
    & .dots-container{
      display: none;
    }
    & .main-image-container{
      margin-bottom: 10px;
    }
  }
  & .buttons-container{
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
