@import 'styles/main';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

ul, p, li, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

img, svg {
  vertical-align: unset;
}

body {
	font-family: 'Cera Pro Bold', sans-serif;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
  background: #ededed;
}

button {
	font-family: 'Cera Pro Bold', sans-serif;
	background: transparent;
	border: none;
	cursor: pointer;
	outline: none;
}

.container {
  width: 100%;
  max-width: 1209px;
  margin: 0 auto;
  padding: 0 90px;
}

.transparent{
  background-color: transparent;

  .modal-content{
    align-items: center;
    border: none;
    background-color: transparent;
  }
}

.login-modal {
  width: fit-content;
}

.popover{
  &-inner{
    background: transparent;
  }
  & .popover-content {
    padding: 0;
  }
  & .popover-arrow {
    display: none;
  }
}

.tooltip-custom {
  display: inline-flex;
  padding: 35px 30px 45px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px 0px 20px 20px;
  background: #222B36;
  position: relative;
  width: fit-content;
  max-width: 400px;
  text-align: left;

  &__list{
    list-style: inside;
    &-item{

    }
  }

  &__title {
    font-family: 'Cera Pro Bold', sans-serif;
    font-weight: 700;
    color: #FFF;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 14px;
  }
  &__text {
    color: #FFF;
    font-family: 'Cera Pro Regular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    span {
      font-family: 'Cera Pro Bold', sans-serif;
      font-weight: 700;
    }
  }
}

.rating-popup {
  display: inline-flex;
  padding: 35px 30px 45px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0 20px 20px 20px;
  background: #222B36;
  position: relative;
  width: 327px;
  color: var(--white);
  max-width: 327px;

  &__close {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: all 0.3s;

    svg {
      width: 100%;
      height: 100%;
      fill: #000000;
      transition: all 0.3s;
    }

    &:hover {
      svg {
        fill: #b50119;
        transition: all 0.3s;
      }
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__col {
    width: 50%;
    color: #FFF;
    font-family: 'Cera Pro Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;

    &._bold {
      font-family: 'Cera Pro Bold';
      font-weight: 700;
    }
  }
}

.rang-popup__text {
  color: #FFF;
  font-family: 'Cera Pro Regular', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;

  span {
    font-family: 'Cera Pro Bold', sans-serif;
    font-weight: 700;
  }
}

.flash-circle {
  position: absolute;
  border-radius: 50%;
  z-index: 88;
  width: 100px;
  height: 100px;
  background: #FFCBC5 no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.flash-heart {
  position: absolute;
  //border-radius: 50%;
  z-index: 99;
  width: 48px;
  height: 48px;
  background: url("assets/images/heart.svg") no-repeat center center;
  background-size: contain;
  pointer-events: none;

  @media (max-width: 1279px) {
    width: 62px;
    height: 62px;
  }
}

body .modal {
  transform: translateY(15%);

  @media (max-width: 1279px) {
    overflow: hidden;
    transform: translateY(10%);

    .modal-dialog{
      margin: 0 auto;
      max-height: 100%;
    }
  }

  @media (max-width: 768px) {
    transform: translateY(0%);
  }
}

.login-modal {
  .modal-content {
    background-color: unset;
    border: none;
  }
}

figcaption{
  display: none;
}
/* Importing Bootstrap SCSS file. */
// Временно закомментировал это подключение. Так как стили из _reboot.scss все ломают. Отключи их пожалуйста. Если по стилям мне надо будет что-то сбросить я это сделаю тут в ручную. В этом ребуте из бутсрапа много лишнего и он мои стили закрывает.
//@import './node_modules/bootstrap/scss/bootstrap';

/* Importing Datepicker SCSS file. */
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';
