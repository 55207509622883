@font-face {
	font-family: 'Cera Pro Regular';
	src: url('../../assets/fonts/CeraPro-Regular.otf') format('opentype');
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Handjet';
	src: url('../../assets/fonts/Handjet-Bold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cera Pro Medium';
	src: url('../../assets/fonts/CeraPro-Medium.otf') format('opentype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cera Pro Bold';
	src: url('../../assets/fonts/CeraPro-Bold.otf') format('opentype');
	font-style: normal;
	font-display: swap;
}
